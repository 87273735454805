import React from 'react';
import Page from './Page'

import PixBoxLayout from '../layouts/PixBoxLayout';
import Separator from '../elements/Separator';
import PixBoxMenu from '../menus/PixBoxMenu';
import PageHeaderOutline from '../layouts/PageHeaderOutline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Page.css';
import './OrderRMAPage.css';

class OrderRMAPage extends Page {

  render() {
    return (
      <div className="OrderRMAPage Page">
        <PageHeaderOutline title="Returns & Exchanges" top={this._backButton()}></PageHeaderOutline>
        <p>How can we assist you with your order?</p>
        <Separator></Separator>
        <PixBoxMenu>
          <PixBoxLayout onClick={(evt, history)=>{this.goto(evt, history,"/rma/replace")}}>
            <div className="Icon"><FontAwesomeIcon icon={["fal","fragile"]} /></div>
            <div className="Title">Replace A Product</div>
            <p className="Description">Replacement for a broken or defective product.</p>
          </PixBoxLayout>
          <PixBoxLayout onClick={(evt, history)=>{this.goto(evt, history,"/rma/exchange")}}>
            <div className="Icon"><FontAwesomeIcon icon={["fal","sync"]} /></div>
            <div className="Title">Exchange A Product</div>
            <p className="Description">Request to exchange a product for a different one.</p>
          </PixBoxLayout>
          <PixBoxLayout onClick={(evt, history)=>{this.goto(evt, history,"/rma/refund")}}>
            <div className="Icon"><FontAwesomeIcon icon={["fal","money-check-alt"]} /></div>
            <div className="Title">Request A Refund</div>
            <p className="Description">Return a product to receive a refund.</p>
          </PixBoxLayout>
        </PixBoxMenu>
      </div>
    );
  }
}

export default OrderRMAPage
