import { dot, reg, arrange } from '../lib/obj'
import { fetch2 } from '../lib/fetch2'

const AWS_BUCKET_URL = "https://support-files.magbak.com.s3.amazonaws.com/";

/**
 * Sends a POST request to AWS S3 Bucket
 */
export const awsPostS3File = function(opts = {}) {
  console.log("bucketPostFile",opts);

  // Get the info
  let file = opts.file || {};
  let prereq = opts.prereq || {};
  let path = prereq.path || "misc/files";

  // Prepare the url
  const url = prereq.url || opts.url || AWS_BUCKET_URL;

  // Prepare the formdata for the upload
  var body = new FormData();
  body.append("Content-Type", file.type);
  body.append("key",`${path}/${file.name}`);
  body.append("policy", dot(prereq,"fields.Policy"));
  body.append("x-amz-algorithm", dot(prereq,"fields.X-Amz-Algorithm"));
  body.append("x-amz-credential", dot(prereq,"fields.X-Amz-Credential"));
  body.append("x-amz-date", dot(prereq,"fields.X-Amz-Date"));
  body.append("x-amz-security-token", dot(prereq,"fields.X-Amz-Security-Token"));
  body.append("x-amz-signature", dot(prereq,"fields.X-Amz-Signature"));
  body.append("file",file);

  console.log("POSTING BODY: ------");
  // Display the key/value pairs
  for (var pair of body.entries()) {
      console.log(pair[0]+ ', ' + pair[1]);
  }
  console.log("--------------------");

  // Send the data
  // return fetch2(url, {
  //   method: 'POST',
  //   body: body
  // });

  // Setup the request and callbacks
  var request = new XMLHttpRequest();
  request.onloadstart = opts.onStart;
  request.onabort = opts.onAbort;
  request.onerror = opts.onError;
  request.onload = opts.onLoad;
  request.ontimeout = opts.onTimeout;
  request.onloadend = opts.onEnd;

  if (opts.onProgress) {
    request.upload.onprogress = opts.onProgress;
  }
  
  // Post the request
  request.open("POST",url);
  request.send(body);
}
