import React from 'react';
import Page from './Page'

import PixBoxLayout from '../layouts/PixBoxLayout';
import Separator from '../elements/Separator';
import PixBoxMenu from '../menus/PixBoxMenu';
import PageHeaderOutline from '../layouts/PageHeaderOutline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Page.css';
import './OrderHelpPage.css';

class OrderHelpPage extends Page {

  render() {
    return (
      <div className="OrderHelpPage Page">
        <PageHeaderOutline title="Order Help" top={this._backButton()}></PageHeaderOutline>
        <p>How can we assist you with your order?</p>
        <Separator></Separator>
        <PixBoxMenu>
          <PixBoxLayout onClick={(evt, history)=>{this.goto(evt, history,"/order/new")}}>
            <div className="Icon"><FontAwesomeIcon icon={["fal","shopping-cart"]} /></div>
            <div className="Title">New Order</div>
            <p className="Description">Request help with a new order or order process.</p>
          </PixBoxLayout>

          <PixBoxLayout onClick={(evt, history)=>{this.goto(evt, history,"/order/update")}}>
            <div className="Icon"><FontAwesomeIcon icon={["fal","edit"]} /></div>
            <div className="Title">Order Edits</div>
            <p className="Description">Request to edit products, quantities, shipping, etc.</p>
          </PixBoxLayout>

          <PixBoxLayout onClick={(evt, history)=>{this.goto(evt, history,"/order/cancel")}}>
            <div className="Icon"><FontAwesomeIcon icon={["fal","ban"]} /></div>
            <div className="Title">Cancel Order</div>
            <p className="Description">Request to cancel an active or partial order.</p>
          </PixBoxLayout>
        </PixBoxMenu>
        <Separator></Separator>
        <PixBoxMenu>
          <PixBoxLayout onClick={(evt, history)=>{this.goto(evt, history,"/order/status")}}>
            <div className="Icon"><FontAwesomeIcon icon={["fal","question-square"]} /></div>
            <div className="Title">Order Status</div>
            <p className="Description">Lookup order activity and shipping progress.</p>
          </PixBoxLayout>
          <PixBoxLayout onClick={(evt, history)=>{this.goto(evt, history,"/order/incomplete")}}>
            <div className="Icon"><FontAwesomeIcon icon={["fal","tasks"]} /></div>
            <div className="Title">Missing Items</div>
            <p className="Description">Report missing or incomplete order products.</p>
          </PixBoxLayout>
        </PixBoxMenu>
        <Separator></Separator>
        <PixBoxMenu>
          <PixBoxLayout onClick={(evt, history)=>{this.goto(evt, history,"/rma/replace")}}>
            <div className="Icon"><FontAwesomeIcon icon={["fal","fragile"]} /></div>
            <div className="Title">Replace A Product</div>
            <p className="Description">Replacement for a broken or defective product.</p>
          </PixBoxLayout>
          <PixBoxLayout onClick={(evt, history)=>{this.goto(evt, history,"/rma/exchange")}}>
            <div className="Icon"><FontAwesomeIcon icon={["fal","sync"]} /></div>
            <div className="Title">Exchange A Product</div>
            <p className="Description">Request to exchange a product for a different one.</p>
          </PixBoxLayout>
          <PixBoxLayout onClick={(evt, history)=>{this.goto(evt, history,"/rma/refund")}}>
            <div className="Icon"><FontAwesomeIcon icon={["fal","money-check-alt"]} /></div>
            <div className="Title">Request A Refund</div>
            <p className="Description">Return a product to receive a refund.</p>
          </PixBoxLayout>
        </PixBoxMenu>
      </div>
    );
  }
}

export default OrderHelpPage
