import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import thunk  from 'redux-thunk';
import rootReducer from './reducers/RootReducer'
import * as serviceWorker from './serviceWorker';
import { loadState, saveState } from './lib/local-storage';
import { _ } from './lib/underscore';

const store = createStore(
  rootReducer,
  loadState(),
  applyMiddleware(thunk)
);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// Store session in local storage
store.subscribe(_.throttle(() => {
  saveState({
    session: store.getState().session
  });
}, 1000));
