import React from 'react';
import { Link } from 'react-router-dom';
import Popup from './Popup';
import RectButton from '../elements/RectButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import './Popup.css';
import './OrderRefundPopup.css';

import { dot } from '../../lib/obj';
import { closePopup } from '../../actions/PopupActions';
import { connect } from 'react-redux'

const MAX_FULFILLMENT_SPAN = 1000 * 60 * 60 * 24 * 40;

class OrderRefundPopup extends Popup {

  onSignIn(evt, history) {
    window.open('https://magbak.com/account/login','_newtab');
  }

  onNext(evt, history) {
    if (this.props.onNext) {
      this.props.onNext(evt, history);
    }
    this.setState({next:true});
  }

  onRefund(evt, history) {
    if (this.props.onRefund) {
      this.props.onRefund(evt, history);
    }
  }

  missingOrderMessage() {
    return <div>
      <h2 className="Title">We did not find your order</h2>
      <p>Please make sure your email and order number were entered correctly.</p>
      <p>Also, check your email inbox and spam folder for your order confirmation.</p>
      <div className="Controls">
        <RectButton className="Cancel" theme="bare" onClick={(evt,history)=>{this.onSignIn(evt,history)}}>Sign In</RectButton>
        <RectButton className="OK" theme="darkblue" onClick={(evt,history)=>{this.onOk(evt, history)}}>OK</RectButton>
      </div>
    </div>;
  }

  // See https://help.shopify.com/en/manual/orders/order-status#payment-status for more info
  cancelledMessage() {
    let data = this.props.order.data;
    let cancelledAt = new Date(data.cancelledAt);
    let odc = cancelledAt.toString().split(" ");

    let intro;
    if (data.cancelledAt) {
      intro = <div>
        <h2 className="Title">We found your order!</h2>
        <p>Your order was cancelled on {odc[0]} {odc[1]} {odc[2]}, {odc[3]} (US Pacific Time).</p>
      </div>;
    } else {
      intro = <div>
        <h2 className="Title">We found your order!</h2>
      </div>;
    }

    let outro = <p>Please check your email inbox and spam folder for more information from us.</p>;

    let controls = <div className="Controls">
      <RectButton className="Cancel" theme="bare" onClick={(evt,history)=>{this.onSignIn(evt,history)}}>Sign In</RectButton>
      <RectButton className="OK" theme="darkblue" onClick={(evt,history)=>{this.onOk(evt, history)}}>OK</RectButton>
    </div>;

    let message;
    switch (data.financialStatus) {
      case "refunded":
        message = <div>
          {intro}
          <p>The full amount that was paid for the order has been refunded.</p>
          <p>If the refund is being issued to a credit card, depending on your credit card company, it may take an additional 2-10 Business Days after your credit is applied for it to post to your account.</p>
          {outro}
          {controls}
        </div>;
        break;
      case "partially_refunded":
        message = <div>
          {intro}
          <p>A partial amount that was paid for the order has been refunded. A partial refund can be a result of a manual order discount or reflects shipping costs that are generally not refunded.</p>
          <p>If the refund is being issued to a credit card, depending on your credit card company, it may take an additional 2-10 Business Days after your credit is applied for it to post to your account.</p>
          {outro}
          <div className="Controls">
            <RectButton className="Cancel" theme="bare" onClick={(evt,history)=>{this.onSignIn(evt,history)}}>Sign In</RectButton>
            <RectButton className="OK" theme="darkblue" onClick={(evt,history)=>{this.onRefund(evt, history)}}>GET REFUND</RectButton>
          </div>
        </div>;
        break;
      case "voided":
        message = <div>
          <p>Any authorized payment amount has been voided.</p>
          {outro}
          {controls}
        </div>;
        break;
    }
    return message;
  }

  fulfillmentMessage() {
    let data = this.props.order.data;
    let processedAt = new Date(data.processedAt);
    let odp = processedAt.toString().split(" ");

    let intro = <div>
      <h2 className="Title">We found your order!</h2>
      <p>Your order was processed on {odp[0]} {odp[1]} {odp[2]}, {odp[3]} (US Pacific Time).</p>
    </div>;
    let outro = <p>Please check your email inbox and spam folder for more information from us.</p>;
    let ps = <p>Feel free to submit a <Link to="/order/status-request" onClick={(e)=>this.onClose(e)}>support request</Link> if you have other questions or comments.</p>;

    // Look for eligible fulfillments
    let efs = [];
    let time = Date.now();
    data.fulfillments.forEach((f) => {
      let fd = new Date(f.createdAt);
      let span = time - fd.getTime();
      if (span <= MAX_FULFILLMENT_SPAN) {
        efs.push(efs);
      }
    });

    let message;
    switch (data.fulfillmentStatus) {
      case "partial":
      case "fulfilled":
        if (efs.length > 0) {
          if (this.state.next) {
            message = <div className="Message">
              <h2 className="Title">Consider the following...</h2>
              <p>
                Sometimes the best way to make a difference is to pay it forward!
                Before you proceed with your return, please take a minute to look at some ways you may be able to re-purpose your items.
                Maybe you can make someone's day or even help the environment :)
              </p>
              <p>Instead of returning:</p>
              <ul>
                <li>Gift it to a friend or family member</li>
                <li>Donate to someone in need</li>
                <li>Recycle</li>
              </ul>
              <div className="Controls">
                <RectButton className="Cancel" theme="bare" onClick={(evt,history)=>{this.onSignIn(evt,history)}}>Sign In</RectButton>
                <RectButton className="OK" theme="darkblue" onClick={(evt,history)=>{this.onRefund(evt, history)}}>GET REFUND</RectButton>
              </div>
            </div>;
          } else {
            message = <div className="Message">
              {intro}
              <p>The items below are eligible for a refund:</p>
              <ul>
                {data.fulfillments.map((f)=><li>
                  <p>Sent via {f.trackingCompany} {f.trackingNumbers.map((t,i)=><a href={f.trackingURLs[i]} target="_blank">{t}</a>)}</p>
                  <ul>
                    {f.items.map((i)=><li>{i.quantity} &times; {i.name}</li>)}
                  </ul>
                </li>)}
              </ul>
              <div className="Controls">
                <RectButton className="Cancel" theme="bare" onClick={(evt,history)=>{this.onSignIn(evt,history)}}>Sign In</RectButton>
                <RectButton className="OK" theme="darkblue" onClick={(evt,history)=>{this.onNext(evt, history)}}>NEXT</RectButton>
              </div>
            </div>;
          }

        }
        else {
          message = <div className="Message">
            {intro}
            <p>Unfortunately, your order is no longer eligible for a refund. Refunds can only be requested within 30 days of product delivery.</p>
            <p>You can request to replace a broken or defective product <Link to="/rma/replace" onClick={(e)=>this.onClose(e)}>here</Link>.</p>
            <div className="Controls">
              <RectButton className="Cancel" theme="bare" onClick={(evt,history)=>{this.onSignIn(evt,history)}}>Sign In</RectButton>
              <RectButton className="OK" theme="darkblue" onClick={(evt,history)=>{this.onOk(evt, history)}}>OK</RectButton>
            </div>
          </div>;
        }
        break;
      case "restocked":
        message = <div className="Message">
          {intro}
          <p>All items have been restocked because of an order refund or cancellation.</p>
          {outro}
          <div className="Controls">
            <RectButton className="Cancel" theme="bare" onClick={(evt,history)=>{this.onSignIn(evt,history)}}>Sign In</RectButton>
            <RectButton className="OK" theme="darkblue" onClick={(evt,history)=>{this.onOk(evt, history)}}>OK</RectButton>
          </div>
        </div>;
        break;
      default:
        message = <div className="Message">
          {intro}
          <p>It is pending shipment.</p>
          <p>Orders typically ship in 1-3 business days. Please check your email inbox and spam folder for more details from us.</p>
          <div className="Controls">
            <RectButton className="Cancel" theme="bare" onClick={(evt,history)=>{this.onSignIn(evt,history)}}>Sign In</RectButton>
            <RectButton className="OK" theme="darkblue" onClick={(evt,history)=>{this.onOk(evt, history)}}>OK</RectButton>
          </div>
        </div>;
    }
    return message;
  }

  render() {
    let order = this.props.order;
    let data = order.data;

    let message;
    if (order.status == "missing") {
      message = this.missingOrderMessage();
    }
    else if (this.cancelledMessage()) {
      message = this.cancelledMessage();
    }
    else {
      message = this.fulfillmentMessage();
    }

    return (
      <div className="Popup OrderRefundPopup" ref="root">
        <div className="PopupFrame" onClick={(e)=>this.onFrameClick(e)}>
          {message}
        </div>
        <div className="Close"><FontAwesomeIcon icon={["fal","times"]} /></div>
      </div>
    );
  }
}


const mapState = (state, props) => {
  let order = dot(state,["support","status","orders",props.order]);
  return {
    order: order || {}
  }
};

const mapDispatch = (dispatch) => {
  return {
    closePopup: opts => dispatch(closePopup(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(OrderRefundPopup)
